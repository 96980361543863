/* stylelint-disable value-no-vendor-prefix */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  letter-spacing: 0.03em;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  &::before,
  &::after {
    box-sizing: border-box;
  }

  // &::-webkit-scrollbar {
  //   display: none; // Chrome
  // }
}

img {
  border: 0;
}

a {
  text-decoration: none;
}

select,
input,
textarea {
  font-size: 16px;
}

input,
button {
  -webkit-appearance: none;
}

input::-webkit-contacts-auto-fill-button {
  position: absolute;
  right: 0;
  display: none !important;
  pointer-events: none;
  visibility: hidden;
}

input {
  background-color: transparent;
  background-image: none;
  border: 0;
}

button {
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 0;

  &:disabled {
    cursor: not-allowed;
  }
}

input:is([type='button'], [type='submit'], [type='reset']),
input[type='file']::file-selector-button,
button {
  color: initial;
} // override safari default blue
