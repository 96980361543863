@use '/src/styles/constants/colors';
@use '/src/styles/mixins/position';

.dropdown {
  position: relative;
  width: 100%;
  background: colors.$MAIN_CONTENTS_BACKGROUND_COLOR;
  border-bottom: 1px solid colors.$INPUT_BORDER;

  button {
    width: 100%;
    padding: 10px;
    color: colors.$WHITE;
    text-align: left;
  }

  > button {
    position: relative;

    &::after {
      @include position.center;
      right: 10px;
      content: '';
      border-top: 6px solid colors.$WHITE;
      border-right: 5px solid transparent;
      border-bottom: 6px solid none;
      border-left: 5px solid transparent;
    }
  }

  .dropdownContents {
    position: absolute;
    z-index: 99;
    width: 100%;
    background: #393939;
    border: 1px solid colors.$MAIN_CONTENTS_BACKGROUND_COLOR;
    border-top: 1px solid colors.$INPUT_BORDER;

    li {
      &:hover {
        button {
          background-color: #474b52;
        }
      }
    }
  }
}
