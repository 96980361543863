@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;

  .logo {
    box-sizing: border-box;
    width: 220px;
    height: 100%;
    padding: 33px 30px;
    font-size: 26px;
    font-weight: 300;
    color: colors.$MAIN_WHITE_COLOR;
    text-align: center;
    letter-spacing: 3px;
    background-color: colors.$MAIN_CONTENTS_BACKGROUND_COLOR;
  }

  .user {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 30px;

    > li {
      position: relative;
      min-width: 80px;
      height: 100%;
      margin: 0 10px;

      > button,
      > p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 14px;
        color: colors.$MAIN_WHITE_COLOR;

        span {
          display: block;
          margin-right: 5px;
          font-size: 30px;
        }
      }
    }
  }
}
