@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.dashBoardWrap {
  .ss {
    ul {
      @include flexbox.flex(flex-start, center);

      li {
        width: 20%;
        margin-right: 15px;

        dl {
          position: relative;
          width: 100%;
          height: 100px;
          padding: 20px;
          background-color: #343336;
          border-radius: 6px;

          dt {
            color: colors.$WHITE;
          }

          dd {
            position: absolute;
            right: 20px;
            bottom: 20px;
            font-size: 30px;
            color: colors.$WHITE;
            text-align: right;
          }
        }
      }
    }
  }
}
