@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 70%);
}
.loadingWrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 30%);
  transform: translate(-50%, -50%);
  @include flexbox.flex(center, center);
}
