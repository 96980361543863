// Global styles
@use '/src/styles/constants/colors';
@import 'base/reset';
@import 'base/more';
@import 'base/font';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: colors.$MAIN_BACKGROUND_COLOR;
}
