@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.filterWrap {
  @include flexbox.flex(flex-end, center);
  margin-bottom: 20px;

  > p {
    color: colors.$WHITE;
  }

  .dropdownWrap {
    width: 130px;
    margin-left: 10px;
    @include flexbox.flex(center, center);
    background-color: #393939;
  }

  > button {
    width: 100px;
    margin-left: 10px;
    background-color: colors.$WHITE;
  }
}

.nftList {
  margin-bottom: 30px;

  thead {
    tr {
      th {
        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(9) {
          width: 5%;
        }
        width: 10%;
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      &.errorItem {
        td {
          color: colors.$ERROR_COLOR;

          a {
            color: colors.$ERROR_COLOR;
          }
        }
      }

      td {
        text-align: center;
        word-break: break-all;
      }
    }
  }
}
