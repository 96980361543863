@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.pagination {
  @include flexbox.flex(center, center);

  li {
    &.first,
    &.last {
      margin-top: 1px;
    }

    button {
      padding: 0 10px;
      font-size: 14px;
      color: colors.$MAIN_WHITE_COLOR;

      &.disabled {
        display: none;
      }
    }
  }
}
