@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.react-tabs {
  &__tab-list {
    @include flexbox.flex(flex-start, center);
    color: colors.$MAIN_WHITE_COLOR;
  }

  &__tab {
    padding: 10px 0;
    margin-right: 50px;
    cursor: pointer;

    &--selected {
      color: #b38c5f;
      border-bottom: 2px solid #b38c5f;
    }
  }
}
