@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.nftWrap {
  min-height: 100%;

  .titleWrap {
    @include flexbox.flex(space-between, center);

    h2 {
      margin: 0 0 30px;
      font-size: 24px;
      font-weight: 500;
      color: colors.$MAIN_WHITE_COLOR;
    }

    button {
      width: 130px;
    }
  }

  article {
    width: 100%;
    padding: 20px;
    font-size: 14px;
    background-color: colors.$MAIN_CONTENTS_BACKGROUND_COLOR;
  }

  .checkWallet {
    @include flexbox.flex(center, center);
    min-height: 790px;
    color: colors.$WHITE;
  }
}
