@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.modalPortalWrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 70%);
  transform: translate(-50%, -50%);
  @include flexbox.flex(center, center);
}
