$MAIN_WHITE_COLOR: #f8f8f8;
$MAIN_COLOR: #b38c5f;
$MAIN_BACKGROUND_COLOR: #232225;
$MAIN_CONTENTS_BACKGROUND_COLOR: #2f2e32;

$SUB_ACTIVE_COLOR: #e4c396;
$SUCCESS_COLOR: #74e092;
$TABLE_ERROR_COLOR: #9a0000;
$ERROR_COLOR: #fa0303;
$INPUT_BORDER: #3c3b3f;

$WHITE: #ffffff;

$RATE_A: #8257f6;
$RATE_B: #f3cf57;
$RATE_C: #f15877;
$RATE_D: #45c3f3;
