@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.userWrap {
  width: 100%;
  overflow-x: hidden;

  h2 {
    margin: 0 0 30px;
    font-size: 24px;
    font-weight: 500;
    color: colors.$MAIN_WHITE_COLOR;
  }

  .tableWrap {
    width: 100%;
    overflow-x: auto; // 테이블 컨테이너에 가로 스크롤을 적용합니다.
    padding-bottom: 15px; // 스크롤바를 위한 여백을 추가합니다.

    // WebKit 브라우저 (Chrome, Safari 등)를 위한 스크롤바 스타일
    &::-webkit-scrollbar {
      height: 8px; // 스크롤바의 높이
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; // 스크롤바 트랙의 배경색
    }

    &::-webkit-scrollbar-thumb {
      background: #888; // 스크롤바 썸의 색상
      border-radius: 4px; // 스크롤바 썸의 모서리를 둥글게
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555; // 스크롤바 썸에 마우스를 올렸을 때의 색상
    }
  }
}

.tableWrap {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  width: 100%;
  min-height: 680px;
  padding: 20px;
  margin-bottom: 40px;
  font-size: 14px;
  background-color: colors.$MAIN_CONTENTS_BACKGROUND_COLOR;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; // 모바일 디바이스에서 부드러운 스크롤을 위해

  table {
    margin-bottom: 30px;
    width: max-content; // 테이블 내용에 맞춰 너비 조정
    min-width: 100%; // 테이블이 컨테이너보다 작아지지 않도록
    border-collapse: collapse;

    thead {
      tr {
        th {
          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            text-align: left;
          }
          padding: 16px 10px;
          font-weight: 600;
          color: colors.$MAIN_WHITE_COLOR;
          text-align: right;
        }
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          td {
            border-bottom: 1px solid colors.$INPUT_BORDER;
          }
        }

        td {
          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            text-align: left;
          }
          padding: 16px 10px;
          margin: 0;
          color: #d6d7d9;
          text-align: right;

          a {
            color: colors.$MAIN_WHITE_COLOR;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.tableWrap table {
  min-width: max-content;
}