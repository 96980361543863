@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.form {
  width: 100%;
  font-size: 14px;

  input {
    width: calc(100% - 100px);
    padding: 10px;
    font-size: 14px;
    color: colors.$WHITE;
    border-bottom: 1px solid colors.$INPUT_BORDER;
  }

  .makerAddress {
    width: calc(100% - 100px);
    padding: 10px;
    font-size: 14px;
    color: colors.$WHITE;
    border-bottom: 1px solid colors.$INPUT_BORDER;
  }

  .centroid {
    position: relative;
    width: calc(100% - 100px);

    span {
      position: absolute;
      top: 50%;
      left: 10px;
      margin-right: 10px;
      color: colors.$MAIN_COLOR;
      transform: translateY(-50%);

      &:nth-of-type(2) {
        left: 120px;
      }
    }

    input {
      width: 100px;
      padding-left: 30px;
      margin-right: 10px;
      color: colors.$WHITE;
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    color: colors.$WHITE;
    resize: none;
    background-color: transparent;
    border: 1px solid colors.$INPUT_BORDER;
  }

  .dropdpownWrap {
    width: calc(100% - 100px);
  }

  .formGroup {
    @include flexbox.flex(flex-start, center);
    margin-bottom: 20px;

    label {
      min-width: 100px;
      color: colors.$WHITE;
    }
  }

  .resourceFileName {
    display: block;
    margin-left: 100px;
    color: colors.$WHITE;

    li {
      margin-bottom: 4px;
    }
  }

  .errorMsg {
    color: colors.$ERROR_COLOR;
  }

  .mintingBtnWrap {
    @include flexbox.flex(flex-end, center);
    margin-top: 50px;
  }
}
