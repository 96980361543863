@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.userWrap {
  h2 {
    margin: 0 0 30px;
    font-size: 24px;
    font-weight: 500;
    color: colors.$MAIN_WHITE_COLOR;
  }
}

.tableWrap {
  width: 100%;
  min-height: 680px;
  padding: 20px;
  margin-bottom: 40px;
  font-size: 14px;
  background-color: colors.$MAIN_CONTENTS_BACKGROUND_COLOR;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; // 모바일 디바이스에서 부드러운 스크롤을 위해

  table {
    margin-bottom: 30px;
    width: max-content; // 테이블 내용에 맞춰 너비 조정
    min-width: 100%; // 테이블이 컨테이너보다 작아지지 않도록
    border-collapse: collapse;

    thead {
      tr {
        th {
          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            text-align: left;
          }
          padding: 16px 10px;
          font-weight: 600;
          color: colors.$MAIN_WHITE_COLOR;
          text-align: right;
        }
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          td {
            border-bottom: 1px solid colors.$INPUT_BORDER;
          }
        }

        td {
          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            text-align: left;
          }
          padding: 16px 10px;
          margin: 0;
          color: #d6d7d9;
          text-align: right;

          a {
            color: colors.$MAIN_WHITE_COLOR;
            text-decoration: none;
          }
        }
      }
    }
  }
}


