@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.resourceUploadWrap {
  width: 500px;
  padding: 30px;
  background-color: colors.$MAIN_BACKGROUND_COLOR;

  .btnWrap {
    @include flexbox.flex(space-between, center);
    width: 100%;
    margin-top: 60px;

    button {
      width: 48%;
    }
  }
}

.loading {
  color: colors.$MAIN_COLOR;
  text-align: center;
}

.notice {
  margin-bottom: 20px;
  font-size: 12px;
  color: colors.$MAIN_COLOR;
}

.resultArea {
  max-height: 200px;
  padding: 10px 0;
  overflow: auto;

  p {
    display: block;
    margin-bottom: 5px;
    font-size: 13px;
  }

  .totalMsg {
    color: colors.$MAIN_COLOR;
  }

  .successMsg {
    color: colors.$SUCCESS_COLOR;
  }

  .errorMsg {
    color: colors.$ERROR_COLOR;
  }
}
