@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.whiteListWrap {
  width: 500px;
  padding: 30px;
  background-color: colors.$MAIN_BACKGROUND_COLOR;

  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    color: colors.$WHITE;
    resize: none;
    background-color: transparent;
    border: 1px solid colors.$INPUT_BORDER;
  }

  .btnWrap {
    @include flexbox.flex(space-between, center);
    width: 100%;
    margin-top: 60px;

    button {
      width: 48%;
    }
  }
}
.checkbox {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

.scrollableForm {
  max-height: 80vh; // 뷰포트 높이의 80%로 최대 높이 설정
  overflow-y: auto; // 세로 스크롤 활성화
  padding-right: 15px; // 스크롤바 공간 확보

  // 웹킷 브라우저용 스크롤바 스타일 (선택적)
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}