@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

button {
  width: 100%;
  height: 46px;

  &.clickActive {
    color: colors.$SUB_ACTIVE_COLOR !important;
    border: 2px solid colors.$MAIN_COLOR !important;
  }
}
