@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.loginWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  height: 100vh;
  margin: 0 auto;

  h2 {
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 600;
    color: colors.$MAIN_WHITE_COLOR;
    text-align: center;
  }

  form {
    width: 350px;

    .formGroup {
      margin-bottom: 20px;

      label {
        font-size: 12px;
        color: colors.$MAIN_WHITE_COLOR;
      }

      input {
        width: 100%;
        padding: 10px 0;
        color: colors.$MAIN_WHITE_COLOR;
        border-bottom: 1px solid #dddddd;
      }
    }

    .errorMsg {
      margin-bottom: 30px;
      color: #b90000;
    }
  }
}
