.subMenuWrap {
  position: absolute;
  top: 90px;
  right: 0;
  display: none;
  width: 80px;
  height: 0;
  background-color: antiquewhite;

  &.active {
    display: block;
    height: 50px;
  }

  li {
    a,
    button {
      display: block;
      width: 100%;
      padding: 15px 10px;
      font-size: 14px;
      color: #000000;
      text-align: center;
    }
  }
}
