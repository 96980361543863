@use '/src/styles/constants/colors';

.wrapper {
  display: flex;

  .main {
    display: flex;
    width: calc(100% - 220px);
    min-height: calc(100vh - 90px);
    padding: 30px;
    border-top: 1px solid #424242;

    .container {
      width: 1440px;
    }
  }
}
