@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

button {
  width: 100%;
  height: 46px;

  &.clickActive {
    color: colors.$SUB_ACTIVE_COLOR !important;
    border: 2px solid colors.$MAIN_COLOR !important;
  }
}

.createBtn {
  margin-top: 70px;
  color: colors.$MAIN_WHITE_COLOR;
  background-color: colors.$MAIN_COLOR;
}

.editBtn,
.addBtn {
  color: colors.$WHITE;
  background-color: colors.$MAIN_COLOR;
}

.cancelBtn {
  color: #acabad;
  background-color: colors.$MAIN_CONTENTS_BACKGROUND_COLOR;
}

.idCheckBtn {
  position: absolute;
  right: 0;
  width: 80px;
  height: 30px;
  color: #acabad;
}

.mintingNFT,
.openNFT {
  width: 300px;
  color: colors.$WHITE;
  background-color: colors.$MAIN_COLOR;
}

.disabled {
  display: none;
}

.active {
  width: 25px;
  height: 25px;
  color: colors.$MAIN_COLOR !important;
  border: 1px solid #958a8a;
  @include flexbox.flex(center, center);
}

.loginBtn {
  display: block;
  width: 100%;
  padding: 10px 0;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 700;
  background-color: colors.$MAIN_WHITE_COLOR;
}

.uploadBtn,
.whiteListBtn {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  background-color: colors.$MAIN_WHITE_COLOR;
  border-radius: 6px;
}

.downloadBtn {
  background-color: #393939;
}
