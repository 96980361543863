@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.input {
  width: calc(100% - 100px);
  padding: 10px;
  font-size: 14px;
  color: colors.$WHITE;
  border-bottom: 1px solid colors.$INPUT_BORDER;

  &:disabled {
    background-color: red;
  }
}
