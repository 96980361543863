@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.listWrap {
  @include flexbox.flex(flex-start, center);
  width: calc(100% - 100px);

  li {
    margin-right: 10px;

    button {
      width: 70px;
      height: 70px;
      font-size: 18px;
      color: #949395;
      background-color: #29282b;
      border: 1px solid #949395;
      border-radius: 6px;
    }
  }
}

.usageWrap {
  li {
    button {
      &::after {
        display: block;
        margin-top: 5px;
        font-size: 11px;
      }
    }
  }
}
