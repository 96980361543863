@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.formGroup {
  @include flexbox.flex(flex-start, center);
  position: relative;
  margin-bottom: 20px;

  .label {
    min-width: 120px;
    font-size: 14px;
    color: colors.$WHITE;
  }
}
