@use '/src/styles/constants/colors';

.aside {
  width: 220px;
  background-color: colors.$MAIN_CONTENTS_BACKGROUND_COLOR;

  nav {
    width: 220px;
    height: 100%;

    a {
      position: relative;
      display: block;
      padding: 10px 40px;
      font-size: 14px;
      font-weight: 300;
      color: colors.$MAIN_WHITE_COLOR;
      letter-spacing: 1px;

      &.isActive {
        font-weight: 500;
        background: linear-gradient(to right, colors.$MAIN_CONTENTS_BACKGROUND_COLOR, #494c57);

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          width: 4px;
          height: 48px;
          content: '';
          background: colors.$MAIN_COLOR;
          transform: translate(0, -50%);
        }
      }

      &:hover {
        font-weight: 500;
        background: linear-gradient(to right, colors.$MAIN_CONTENTS_BACKGROUND_COLOR, #494c57);
      }

      span {
        margin-right: 10px;
        font-size: 28px;
        vertical-align: middle;
      }
    }
  }
}
