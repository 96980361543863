@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.whiteListWrap {
  width: 500px;
  padding: 30px;
  background-color: colors.$MAIN_BACKGROUND_COLOR;

  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    color: colors.$WHITE;
    resize: none;
    background-color: transparent;
    border: 1px solid colors.$INPUT_BORDER;
  }

  .btnWrap {
    @include flexbox.flex(space-between, center);
    width: 100%;
    margin-top: 60px;

    button {
      width: 48%;
    }
  }
}
